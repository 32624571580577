export const getSimulationsState = ({ simulateTournee }) => simulateTournee
// create selector get a hold on the object reference, if the object didnt change, it won't redo the treatment
export const getSimulationTours = (state) => getSimulationsState(state).tours

export const getSimulationUnserved = (state) =>
  getSimulationsState(state).unservedOrders

export const getFocusedTours = (state) => getSimulationsState(state).focusedTours
export const getToursMarkers = (state) => {
  return getSimulationsState(state).tours.map((t) => t.markers)
}
export const getcheckedRec = (state) => {
  return getSimulationsState(state).tourRecChecked
}
export const getFleet = (state) => getSimulationsState(state).fleet
export const getCheckedAllOrder = (state) =>
  getSimulationsState(state).checkedAllOrder
export const getNbOrderChecked = (state) => getSimulationsState(state).nbOrderChecked
export const getTourType = (state) => getSimulationsState(state).isOnePickUp

export const getIsErrorAffectPlanning = (state) =>
  getSimulationsState(state).isErrorAffectPlanning

export const getSimulationsErrorMessage = (state) =>
  getSimulationsState(state).errorMessage
export const getIsViewPlannification = (state) =>
  getSimulationsState(state).isViewPlannification
export const getSimulationsIsLoading = (state) =>
  getSimulationsState(state).isLoading

export const getSimulationIsError = (state) => getSimulationsState(state).isError
export const getIsLoadingUpdateCoursePolaris = (state) =>
  getSimulationsState(state).isLoadingUpdateCoursePolaris

export const getisUpdateCoursePolarisAction = (state) =>
  getSimulationsState(state).isUpdateCoursePolarisAction

export const isTourActionsSimulate = (state) => getSimulationsState(state).tourAction

export const getToursList = (state) => getSimulationsState(state).tourList
export const getCountTours = (state) => getSimulationsState(state).countTours
export const getIsOptimisationCoursesFiles = (state) =>
  getSimulationsState(state).isOptimisationCoursesFiles

export const getWarehouses = (state) => getSimulationsState(state).warehouses

export const getWarehousesCount = (state) =>
  getSimulationsState(state).warehousesCount

export const getIsLoadingWarehouses = (state) =>
  getSimulationsState(state).isLoadingWarehouses

export const getFilterCourseFile = (state) =>
  getSimulationsState(state).filterCourseFile

export const getfilterCourses = (state) => getSimulationsState(state).filterCourses

export const getSelectedCourses = (state) =>
  Object.keys(getSimulationsState(state).selectedCourses)
