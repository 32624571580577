export const COURSE_SOURCE_DUPLICATED = [
  'MDM',
  'EDI_CAFOM',
  'FOURNIER',
  'SCHMIDT',
  'CENTIRO',
  'EMMA',
  'LECLERC',
]

export const COURSE_SOURCE_ETAT_POINTAGE = ['MDM', 'EDI_CAFOM']
export const COURSE_SOURCE_EDIT_PACKAGE = ['MDM', 'EDI_CAFOM']
export const COURSE_SOURCE_CALENDAR = ['MDM', 'EDI_CAFOM', 'SCHMIDT', 'CONFORAMA']
export const COURSE_SOURCE_CALENDAR_DISABLED = ['CONFORAMA']
export const COURSE_SOURCE_BLOQUAGE_PROGRAMMATION = ['MDM', 'EDI_CAFOM']
export const COURSE_SOURCE_BLOQUAGE_CHECK_SEND_MAIL_PROGRAMMATION = [
  'MDM',
  'EDI_CAFOM',
  'CONFORAMA',
]

export const COURSE_SOURCE_DATE_ENLEVEMENT = ['LECLERC']
