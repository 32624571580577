//TODO LINT
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import AccordionList from '../../../../../components/AccordionList'
import ToursTable from '.'

import VolumeIcon from '../../../../../assets/icons/cube.svg'
import EuroIcon from '../../../../../assets/icons/euro.svg'
import TruckIcon from '../../../../../assets/icons/polaris/TruckIcon'
import Horloge from '../../../../../assets/icons/polaris/horlog'
import PlaceIcon from '../../../../../assets/icons/place.svg'
import TrajectoireIcon from '../../../../../assets/icons/trajectoire'
import {
  convertirMinutesEnHeuresEtMinutes,
  getCreneauHoursMinutes,
} from '../../../../../utils/utils'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '../../../../../components/switch/SwitchComponent'
import Typography from '@mui/material/Typography'
import { StyledDivSwitch, StyledHeaderPlanning } from './style'
import PoidsLivraisonIcon from '../../../../../assets/icons/polaris/PoidsLivraison.svg'
import PoidsEnlevementIcon from '../../../../../assets/icons/polaris/PoidsEnlevement.svg'
import TruckNumberIcon from '../../../../../assets/icons/polaris/MdiTruckDeliveryOutline'
import ActionsTours from './actionTours'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
export default function ToursDetails({
  planning,
  planningVisits,
  setTabIndex,
  tabIndex,
  focusedTours,
  isRecalculateLoading,
  handleCheck,
  handleCheckAll,
  onChecked,
  checked,
  setOpenDeattributeDialog,
  isOptimisationCoursesFiles,
  verrouillerTournee,
  deVerrouillerTournee,
  inversionTournee,
  expand,
  onExpend,
  inversionOrderTournee,
  selectedAccodions,
  handleChangeAccordion,
}) {
  const handleDisable = () => {
    return planning
      .map((i) => (i.markers.length === 2 ? false : true))
      .some((obj) => obj === true)
  }

  const onVerrouillerTournee = () => {
    verrouillerTournee(focusedTours)
  }

  const onDeVerrouillerTournee = () => {
    deVerrouillerTournee(focusedTours)
  }
  return (
    <>
      <StyledHeaderPlanning
        value={tabIndex}
        variant="scrollable"
        onChange={(e, y) => {
          setTabIndex(y)
          e.stopPropagation()
        }}
        sx={{
          position: 'sticky',
          top: 0,
          color: '#fff',
          zIndex: 10,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: '#1976d2',
          height: '45px',
          padding: '0 10px',
          justifyContent: 'space-between',
        }}
      >
        Tournées
        <StyledDivSwitch>
          {!isOptimisationCoursesFiles && (
            <FormGroup row spacing={1} alignItems="center">
              <Typography sx={{ alignItems: 'center' }}>Magasin</Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Switch
                    value={checked}
                    defaultChecked={checked}
                    onChange={(e) => {
                      onChecked()
                      e.stopPropagation()
                    }}
                    id={'switch-MagEnt'}
                    disabled={handleDisable()}
                  />
                }
              />
              <Typography sx={{ alignItems: 'center' }}>Entrepot</Typography>
            </FormGroup>
          )}
          <>
            <ActionsTours
              isOptimisationCoursesFiles={isOptimisationCoursesFiles}
              planning={planning}
              focusedTours={focusedTours}
              setOpenDeattributeDialog={setOpenDeattributeDialog}
              onVerrouillerTournee={onVerrouillerTournee}
              onDeVerrouillerTournee={onDeVerrouillerTournee}
              inversionTournee={inversionTournee}
              inversionOrderTournee={inversionOrderTournee}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer', marginLeft: '20px' }}
              icon={expand ? faCompressAlt : faExpandAlt}
              onClick={() => onExpend(!expand)}
            />
          </>
        </StyledDivSwitch>
      </StyledHeaderPlanning>
      <AccordionList
        list={planning.map((tour, i, arr) => {
          console.log(tour)
          return {
            color: tour.color,
            heading: tour.driver?.firstName,
            lock: tour.lock,
            summary: [
              {
                label: <TruckNumberIcon />,
                content: `${(tour.markers.length - 2) / 2} / ${tour.stopsNumber}`,
                unit: 'Missions / Stops',
                show: true,
              },
              {
                label: <img src={PlaceIcon} />,
                content: tour.distance,
                unit: 'Distance',
                show: true,
              },
              {
                label: <img src={EuroIcon} />,
                content: tour.montantPrestataireHT,
                unit: 'Prix',
                show: true,
              },
              {
                label: <img src={PoidsEnlevementIcon} style={{ height: '18px' }} />,
                content: tour.tourPickupWeight,
                unit: 'Poids Livraison',
                show: true,
              },
              {
                label: <img src={PoidsLivraisonIcon} style={{ height: '18px' }} />,
                content: tour.tourDropoffWeight,
                unit: 'Poids Reprises',
                show: true,
              },

              {
                label: <img src={VolumeIcon} />,
                content: tour.totalVolume,
                unit: 'Volume',
                show: true,
              },
              {
                label: (
                  <Horloge color={!tour?.exceededAmplitude ? '#373d57' : 'red'} />
                ),
                content: tour.totalTime,
                unit: !tour?.exceededAmplitude
                  ? 'Temps de service'
                  : `Dépassement de ${convertirMinutesEnHeuresEtMinutes(
                      tour.exceededAmplitude
                    )}`,
                show: true,
                color: !tour?.exceededAmplitude ? '#373d57' : 'red',
              },
              {
                label: <TrajectoireIcon />,
                content: tour.trajetTime,
                unit: 'Temps conduite',
                show: true,
              },
              {
                label: <TruckIcon />,
                content: tour.driver?.vehiculeType,
                unit: 'Vehicule',
                show: true,
              },
            ],
            isFocused: focusedTours.includes(
              arr
                .map((x) => {
                  return x.driver.id
                })
                .indexOf(tour.driver.id)
            ),
            expanded: selectedAccodions.includes(
              arr
                .map((x) => {
                  return x.driver.id
                })
                .indexOf(tour.driver.id)
            ),

            index: arr
              .map((x) => {
                return x.driver.id
              })
              .indexOf(tour.driver.id),
            path: tour.path,
            details: (
              <ToursTable
                tours={planningVisits[i]
                  .filter((item) =>
                    isOptimisationCoursesFiles ? item.type != 'pickup' : item
                  )
                  .map((visit, j) => {
                    return {
                      rejectedKeys: visit.rejectedKeys,
                      checked: visit.checked,
                      order: isOptimisationCoursesFiles ? j : visit.order,
                      location_id: visit.location_id,
                      location_name: visit.location_name,
                      ldv:
                        visit.type == 'depotStart'
                          ? 'Début de la tournée'
                          : visit.type == 'depotEnd'
                          ? 'Fin de la tournée'
                          : visit.ldv,
                      arrival: visit.arrival_time,
                      finish:
                        visit.type == 'depotStart' || visit.type == 'depotEnd'
                          ? visit.arrival_time
                          : visit.finish_time,
                      type: visit.type,
                      day: visit.day,
                      courseRank: visit.courseRank,
                      too_late: visit.too_late,
                      weight: visit.weight,
                      volume: visit.volume,
                      montantPrestataireHT: visit.montantPrestaHT,
                      courseType: visit.courseType,
                      pickup:
                        visit.type == 'pickup'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff1:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff2:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.secondWindowStart,
                              visit.secondWindowEnd,
                              '~'
                            )
                          : '-',
                      courseCode: visit.location_name,
                      salesChanel: visit.salesChanel,
                      indexVisit: j,
                      receptionNumber: visit.location_id,
                      idleTime: visit.idleTime,
                      client: visit.client,
                      prestaTime: visit.prestaTime,
                      codePostale: visit.codePostale,
                      keys: visit.keys,
                      lateBy: visit.lateBy,
                      slotOverRun: visit.slotOverRun,
                      orderGiver: visit.orderGiver,
                    }
                  })}
                index={i}
                isOpen={selectedAccodions.includes(
                  arr
                    .map((x) => {
                      return x.driver.id
                    })
                    .indexOf(tour.driver.id)
                )}
                isRecalculateLoading={isRecalculateLoading}
                handleCheck={handleCheck}
                handleCheckAll={handleCheckAll}
                indexPlanning={i}
                checkedAll={tour.checkedAll}
                nbVisitChecked={tour.nbVisitChecked}
                isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                lock={tour.lock}
              />
            ),
            handleChange: handleChangeAccordion,
          }
        })}
      />
    </>
  )
}
ToursDetails.propTypes = {
  focusedTours: PropTypes.array,
  isRecalculateLoading: PropTypes.bool,
  planning: PropTypes.any,
  planningVisits: PropTypes.any,
  handleCheck: PropTypes.func,
  handleCheckAll: PropTypes.func,
  onChecked: PropTypes.func,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  setSizeTable: PropTypes.func,
  setOpenDeattributeDialog: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  verrouillerTournee: PropTypes.func,
  deVerrouillerTournee: PropTypes.func,
  inversionTournee: PropTypes.func,
  inversionOrderTournee: PropTypes.func,
  selectedAccodions: PropTypes.array,
  handleChangeAccordion: PropTypes.func,
}
